
import Teams from "@/components/elements/Teams.vue";
import Tabs from "@/components/elements/Tabs.vue";

import {Options, Vue} from "vue-class-component";

@Options({
  components: {
    Tabs,
    Teams
  },
  props: {
    team1: {
      required: true
    },
    team2: {
      required: true
    },
    game: {
      required: true
    }
  }
})
export default class Hero extends Vue {
}
