
import Hero from "@/components/elements/Hero.vue";
import Header from "@/components/elements/Header.vue";
import TeamTitle from "@/components/title/TeamTitle.vue";
import Stream from "@/components/elements/Stream.vue";
import {Options, Vue} from "vue-class-component";
import Settings from "@/services/Settings";


@Options({
  components: {
    Stream,
    TeamTitle,
    Header,
    Hero
  },
})
export default class Feed extends Vue {
  private gameId: string | null = null;
  private projectId: string | null = null;
  private teamId: string | null = null;
  private team: any = null;
  private host: string | null = null;
  private loader = false;
  private loaderFeedData = false;
  private game: any = {};
  private page = 1;
  private totalPages = 1;
  private limit = 12;
  private total = 0;
  private feedData = [];

  loadData() {
    if (this.loader) return;
    this.loader = true;
    this.$http.get(this.host + '/public/games/' + this.gameId, {
      params: {}
    }).then((response) => {
      this.game = response.data;
      if(this.game.team1.id === this.teamId) {
        this.team = this.game.team1;
      }
      if(this.game.team2.id === this.teamId) {
        this.team = this.game.team2;
      }
      this.loader = false;
    }, () => {
      this.loader = false;
    });
  }

  loadFeedData() {
    if (this.loaderFeedData) return;
    this.loaderFeedData = true;
    this.$http.get(this.host + '/public/results/' + this.projectId +'?sort_by=time%20desc&page='+ this.page +'&limit=' + this.limit, {
      params: {}
    }).then((response) => {
      this.feedData = [
          ...this.feedData,
          ...response.data.inline.resources
      ] as never[];
      this.total = response.data.total;
      this.loaderFeedData = false;
      this.totalPages = response.data.pages;
    }, () => {
      this.loaderFeedData = false;
    });
  }

  paginateData() {
    this.page+=1;
    this.loadFeedData();
  }

  mounted() {
    this.gameId = this.$route.params.gameId as string;
    this.projectId = this.$route.params.projectId as string;
    this.teamId = this.$route.params.teamId as string;
    const settings = new Settings();
    this.host = settings.getSetting('apiHost');
    this.loadData();
    this.loadFeedData();
  }
}

