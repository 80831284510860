import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"
import _imports_0 from './../../assets/images/platforms/facebook.png'
import _imports_1 from './../../assets/images/platforms/instagram.png'
import _imports_2 from './../../assets/images/platforms/twitter.png'
import _imports_3 from './../../assets/images/platforms/tiktok.png'
import _imports_4 from './../../assets/images/platforms/youtube.png'


const _hoisted_1 = { class: "stream" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "stream-src" }
const _hoisted_4 = {
  key: 0,
  src: _imports_0
}
const _hoisted_5 = {
  key: 1,
  src: _imports_1
}
const _hoisted_6 = {
  key: 2,
  src: _imports_2
}
const _hoisted_7 = {
  key: 3,
  src: _imports_3
}
const _hoisted_8 = {
  key: 4,
  src: _imports_4
}
const _hoisted_9 = { class: "stream-details" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { class: "stream-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (item) => {
      return (_openBlock(), _createElementBlock("a", {
        class: "stream-item",
        key: item.id,
        style: _normalizeStyle({ backgroundImage: 'url(' + item.contentPicture + ')' }),
        target: "_blank",
        onClick: ($event: any) => (_ctx.openImageModal(item))
      }, [
        _createElementVNode("span", _hoisted_3, [
          (item.cat === 1)
            ? (_openBlock(), _createElementBlock("img", _hoisted_4))
            : _createCommentVNode("", true),
          (item.cat === 5)
            ? (_openBlock(), _createElementBlock("img", _hoisted_5))
            : _createCommentVNode("", true),
          (item.cat === 2)
            ? (_openBlock(), _createElementBlock("img", _hoisted_6))
            : _createCommentVNode("", true),
          (item.cat === 10)
            ? (_openBlock(), _createElementBlock("img", _hoisted_7))
            : _createCommentVNode("", true),
          (item.cat === 4)
            ? (_openBlock(), _createElementBlock("img", _hoisted_8))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", {
            class: _normalizeClass(["d-flex flex-justify-between", {  'mb-1': _ctx.streamType==='game' }])
          }, [
            (_ctx.streamType==='game')
              ? (_openBlock(), _createElementBlock("p", _hoisted_10, "@" + _toDisplayString(item.authorName), 1))
              : _createCommentVNode("", true)
          ], 2),
          _createElementVNode("p", _hoisted_11, _toDisplayString(item.contentMessage), 1)
        ])
      ], 12, _hoisted_2))
    }), 128))
  ]))
}