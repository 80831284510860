import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mb-5"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_Hero = _resolveComponent("Hero")!
  const _component_TeamTitle = _resolveComponent("TeamTitle")!
  const _component_Stream = _resolveComponent("Stream")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Header),
    _createVNode(_component_Hero, {
      team1: _ctx.game.team1,
      team2: _ctx.game.team2,
      game: _ctx.game
    }, null, 8, ["team1", "team2", "game"]),
    _createVNode(_component_TeamTitle, { team: _ctx.team }, null, 8, ["team"]),
    _createVNode(_component_Stream, {
      data: _ctx.feedData,
      "stream-type": "game"
    }, null, 8, ["data"]),
    (_ctx.page<_ctx.totalPages)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("a", {
            class: "btn btn-blue btn-outside",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.paginateData()))
          }, "Zobacz więcej postów")
        ]))
      : _createCommentVNode("", true)
  ]))
}