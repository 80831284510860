import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d5b3f63a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "hero-wrapper hero-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Teams = _resolveComponent("Teams")!
  const _component_Tabs = _resolveComponent("Tabs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Teams, {
      team1: _ctx.team1,
      team2: _ctx.team2,
      game: _ctx.game
    }, null, 8, ["team1", "team2", "game"]),
    _createVNode(_component_Tabs, {
      team1: _ctx.team1,
      team2: _ctx.team2,
      game: _ctx.game
    }, null, 8, ["team1", "team2", "game"])
  ]))
}