
import {Options, Vue} from "vue-class-component";

@Options({
  components: {

  },
  props: {
    team1: {
      required: true
    },
    team2: {
      required: true
    },
    game: {
      required: true
    }
  }
})
export default class Tabs extends Vue {
  static GameLink = '/game/{gameId}';
  static TeamFeedLink = '/feed/{gameId}/{projectId}/{teamId}';
  static GameFeedLink = '/fanzone/{gameId}';
  static FanGuideLink = '/fanguide/{gameId}';

  gameLink(gameId: string): string {
    return Tabs.GameLink.replace('{gameId}', gameId);
  }

  teamFeedLink(gameId: string, projectId: string, teamId: string): string {
    return Tabs.TeamFeedLink
        .replace('{gameId}', gameId)
        .replace('{projectId}', projectId)
        .replace('{teamId}', teamId)
  }

  gameFeedLink(gameId: string): string {
    return Tabs.GameFeedLink.replace('{gameId}', gameId);
  }

  fanGuideLink(gameId: string): string {
    return Tabs.FanGuideLink.replace('{gameId}', gameId);
  }

  actualPath(): string {
    return this.$route.path;
  }
}
