export default class Settings {
    private settings: any = {
        apiHost: 'https://api.hashtags.pl',
        storagePath: 'https://api.hashtags.pl/storage/',
    };
    constructor() {
        const host = window.location.host;
        if (host.startsWith('localhost')) {
            // this.settings.apiHost = 'http://localhost:56332';
            this.settings.apiHost = 'https://api.feed.levelpro.com';
            // this.settings.apiHost = 'https://api.feed.levelpro.tech';
            this.settings.storagePath = 'https://api.hashtags.pl/storage/';
        }
        if (host.startsWith('feed.levelpro.tech')) {
            this.settings.apiHost = 'https://api.feed.levelpro.tech'; //'https://api.hashtags.pl';
            this.settings.storagePath = 'https://api.hashtags.pl/storage/'; //'https://api.hashtags.pl/storage/';
        }
        if (host.startsWith('feed.levelpro.com')) {
            this.settings.apiHost = 'https://api.feed.levelpro.com';
            this.settings.storagePath = 'https://api.feed.levelpro.com/storage/';
        }
    }

    getSetting(name: string): string {
        return this.settings[name];
    }

    setSetting(name: string, value: any): void {
        this.settings[name] = value;
    }
}
