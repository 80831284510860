<template>

  <div class="image-wrap">
<!--    <a class="close-icon"></a>-->
    <a  class="image-zoom"
        v-bind:href="postLink"
        target="_blank"
        v-bind:style="{ backgroundImage: 'url(' + imageSrc + ')' }"
    >
       <span class="stream-src">
        <img v-if="postCategory === 1" src="./../../assets/images/platforms/facebook.png"/>
        <img v-if="postCategory === 5" src="./../../assets/images/platforms/instagram.png"/>
        <img v-if="postCategory === 2" src="./../../assets/images/platforms/twitter.png"/>
        <img v-if="postCategory === 10" src="./../../assets/images/platforms/tiktok.png"/>
        <img v-if="postCategory === 4" src="./../../assets/images/platforms/youtube.png"/>
      </span>
<!--          <img-->
<!--              v-bind:src="imageSrc"-->
<!--          >-->
    </a>

  </div>
</template>
<script>
export default {
  props: {
    imageSrc: String,
    postLink: String,
    postCategory: Number
  }
}
</script>
<style>
.image-wrap {
  width: 400px;
  height: 400px;
}
.image-zoom {
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  display: block;
  border: 5px solid white;
  transition: all 0.3s;
  position: relative;
}

.image-zoom:hover {
  opacity: 0.9;
}
.image-zoom .stream-src {
  position: absolute;
  top: 5px;
  right: 5px;
}
.image-zoom .stream-src img {
  width: 32px;
  height: 32px;
}

.modal-container{
  background-color: rgba(0,0,0,0.8);
}
.modal-item {
  background-color: white;
}
@media (max-width: 400px) {
  .image-wrap {
    width: 360px;
    height: 360px;
  }
}
/*.close-icon {*/
/*  !*background-color: white;*!*/
/*  border-radius: 50%;*/
/*  width: 18px;*/
/*  height: 18px;*/
/*  background-image: url("/assets/images/close.png");*/
/*  display: block;*/
/*  position: absolute;*/
/*  top: 0;*/
/*  right:0;*/
/*}*/
</style>
