import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "tabs" }
const _hoisted_2 = {
  key: 0,
  class: "tabs-menu",
  "aria-label": "Person settings"
}
const _hoisted_3 = ["href"]
const _hoisted_4 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.game && _ctx.game.id !== undefined)
      ? (_openBlock(), _createElementBlock("nav", _hoisted_2, [
          _createVNode(_component_router_link, {
            to: _ctx.gameLink(_ctx.game.id)
          }, {
            default: _withCtx(() => [
              _createElementVNode("a", {
                class: _normalizeClass(["tabs-menu-item", {active: _ctx.gameLink(_ctx.game.id) === _ctx.actualPath()}])
              }, "Stats", 2)
            ]),
            _: 1
          }, 8, ["to"]),
          _createElementVNode("a", {
            href: _ctx.teamFeedLink(_ctx.game.id, _ctx.game.projectTeam1Id, _ctx.game.team1.id),
            class: _normalizeClass(["tabs-menu-item", {active: _ctx.teamFeedLink(_ctx.game.id, _ctx.game.projectTeam1Id, _ctx.game.team1.id) === _ctx.actualPath()}])
          }, _toDisplayString(_ctx.game.team1.name), 11, _hoisted_3),
          _createElementVNode("a", {
            href: _ctx.teamFeedLink(_ctx.game.id, _ctx.game.projectTeam2Id, _ctx.game.team2.id),
            class: _normalizeClass(["tabs-menu-item", {active: _ctx.teamFeedLink(_ctx.game.id, _ctx.game.projectTeam2Id, _ctx.game.team2.id) === _ctx.actualPath()}])
          }, _toDisplayString(_ctx.game.team2.name), 11, _hoisted_4),
          _createVNode(_component_router_link, {
            to: _ctx.gameFeedLink(_ctx.game.id)
          }, {
            default: _withCtx(() => [
              _createElementVNode("a", {
                class: _normalizeClass(["tabs-menu-item", {active: _ctx.gameFeedLink(_ctx.game.id) === _ctx.actualPath()}])
              }, "Fanzone", 2)
            ]),
            _: 1
          }, 8, ["to"]),
          _createVNode(_component_router_link, {
            to: _ctx.fanGuideLink(_ctx.game.id)
          }, {
            default: _withCtx(() => [
              _createElementVNode("a", {
                class: _normalizeClass(["tabs-menu-item", {active: _ctx.fanGuideLink(_ctx.game.id) === _ctx.actualPath()}])
              }, "Skarb kibica", 2)
            ]),
            _: 1
          }, 8, ["to"])
        ]))
      : _createCommentVNode("", true)
  ]))
}