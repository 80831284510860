
import {Options, Vue} from "vue-class-component";

@Options({
  components: {

  },
  props: {
    team1: {
      required: true
    },
    team2: {
      required: true
    },
    game: {
      required: true
    }
  }
})
export default class Teams extends Vue {
  currentUnixDate(postDateUnix:number) {
    const current = new Date(postDateUnix*1000);
    let minute = current.getMinutes().toString();
    if (current.getMinutes()<10) {
      minute = '0'+minute.toString();
    }
    const date = `${current.getHours()}:${minute} `;
    // const date = `${current.getHours()}:${current.getMinutes()} ${current.getDate()}.${current.getMonth()+1}.${current.getFullYear()} `;
    return date;
  }

  currentDate(postDate:string) {
    const current = new Date(postDate);
    let minute = current.getMinutes().toString();
    if (current.getMinutes()<10) {
      minute = '0'+minute.toString();
    }
    // const date = `${current.getHours()}:${minute} `;
    const date = `${current.getHours()}:${current.getMinutes()} ${current.getDate()}.${current.getMonth()+1}.${current.getFullYear()} `;
    return date;
  }
}
