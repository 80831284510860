
import {Options, Vue} from "vue-class-component";
import {openModal} from "jenesius-vue-modal"
import ImageModal from "@/components/elements/ImageModal.vue";



@Options({
  components: {
    ImageModal
  },
  props: {
    data: {
      required: true
    },
    streamType: {
      required: true
    }
  }
})
export default class Stream extends Vue {
  currentDate(postDateUnix:number) {
    const current = new Date(postDateUnix*1000);
    let minute = current.getMinutes().toString();
    if (current.getMinutes()<10) {
      minute = '0'+minute.toString();
    }
    const date = `${current.getHours()}:${minute} `;
    // const date = `${current.getHours()}:${current.getMinutes()} ${current.getDate()}.${current.getMonth()+1}.${current.getFullYear()} `;
    return date;
  }
  openImageModal(item:any) {
    openModal(ImageModal, {
      imageSrc: item.contentPicture,
      postLink: item.contentLink,
      postCategory: item.cat
    });
  }
}
